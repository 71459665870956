
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[venueSlug]/[orderingTypeSlug]",
      function () {
        return require("private-next-pages/[venueSlug]/[orderingTypeSlug]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[venueSlug]/[orderingTypeSlug]"])
      });
    }
  